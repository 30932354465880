@import "../../../constants/constants.scss";

.sendReportConfirmationModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  min-height: 11rem;
  padding: 2rem;

  &__TextBlock {
    text-align: center;
    font-family: PulpDisplay;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  &__Buttons {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__SuccessMessage {
    min-height: 1rem;
    > p {
      font-family: PulpDisplay;
    }
  }
}
