@import "../../../constants//constants.scss";

.formQuestionsFormatter {
    
    &__Category {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: $Stablegrey;
        text-align: left;
        display: flex;
        flex-direction: column;
        h4 {
            font-weight: 700;
            font-size: 1.7rem;
            letter-spacing: .15rem;
            padding-bottom: 0rem;
        }
    }
    &__CategoryTop {
        display: flex;
        align-items: center;
        
    }
    &__Trait {
        flex : 1;
        border-top: 1px solid $StableGrayLight04;
        margin-top: 1rem;
        margin-left: 2rem;
    }
    &__Question {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
     
    }


}