@import "../../constants/constants.scss";

.button-connect {
  background-color: $MainYellow;
  color: #ffffff;
  border-radius: 3.125rem;
  font-weight: 700;
  width: 11rem;
  height: 2.25rem;
  font-size: 1.125rem;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border: 2px solid transparent;
  &:hover {
    background-color: #ffffff;
    color: $MainOrange;
    border: 2px solid $MainOrange;
  }
}
