@import "../../constants/constants.scss";

.button-disconnect {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 1.125rem;
  text-decoration: underline;
  &:hover {
    font-size: 1.150rem;
  }
}
