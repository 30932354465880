@import "./constants/constants.scss";

/* Reset rules */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font-family: PulpDisplay;
  // font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button:focus {
  outline: none;
}
a {
  text-decoration: none;
}
input {
  all: unset;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
textarea {
  resize: none;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}

// scrollbar styling (used in the modal when more than 4 files are added)
// A lot of rules are going to be used here so it works on different browsers

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $MainYellow;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  border: solid 3px transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px 20px #7C7F87;
  border: solid 5px transparent;
  border-radius: 20px;
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// TTF
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraLight.ttf");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraBold.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

//EOT
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraLight.eot");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Light.eot");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Regular.eot");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Medium.eot");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-SemiBold.eot");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Bold.eot");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraBold.eot");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

//woff
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraLight.woff");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Light.woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Medium.woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-SemiBold.woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Bold.woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraBold.woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// WOFF2
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraLight.woff2");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Light.woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Regular.woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Medium.woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-SemiBold.woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-Bold.woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PulpDisplay";
  src: url("../public/fonts/PulpDisplay/PulpDisplay-ExtraBold.woff2");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
