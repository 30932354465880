@import "../../constants/constants.scss";

.secondaryButton {
  cursor: pointer;
  height: 50px;
  border-radius: 25px;
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  background-color: white;
  min-width: 160px;
  @media (max-width: $smallWindow) {
    height: 30px;
  }
  &:hover {
    background: $BackgroundColorTwo;
  }
  > p {
    color: $MainGrey;
    font-size: 1.1rem;
    font-weight: 600;
    font-family: PulpDisplay;
  }
}

.secondaryButton:disabled {
  > p {
    color: $StableGrayLight;
  }
}
