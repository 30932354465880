@import "../../../../constants/constants.scss";

.InputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;

  &__QuestionTitle {
    font-size: 18px;
    color: $Stablegrey;
  }

  &__question-answer_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    text-align: left;
  }

  &__fieldContainer {
    max-width: 104px;
  }

  &__fieldContainerBig {
    min-width: 350px;
  }
}

.answerUnderTitle {
  flex-direction: column;
  align-items: flex-start;
}
