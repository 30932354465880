@import "../../../../constants/constants.scss";

.TextAreaContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  &__question-answer_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1;
    max-width: 600px;
  }
}

.answerUnderTitle {
  flex-direction: column;
  align-items: flex-start;
}
