@import "../../../constants/constants.scss";

.forgot-password {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  box-sizing: border-box;
  @media (max-width: $mediumWindow) {
    flex: none;
  }

  .forgot-password-inputs {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    margin-bottom: 0.625rem;
    padding-bottom: 0.625rem;
    > p {
      font-size: 0.9rem;
      margin-bottom: 1.875rem;
      color: $MainGrey;
    }
    .reset-password-success {
      color: $MainGreen;
    }
    .input-container {
      margin-bottom: 1.25rem;
      width: 100%;
    }
  }
  .forget-password-message {
    height: 1.25rem;
    .error-message {
      margin-bottom: 0.625rem;
    }
  }

  .forgot-password-buttons {
    flex: 1;
    .go-to-login {
      font-size: 0.9rem;
      margin-right: 0.625rem;
      font-weight: 700;
      color: $MainBlue;
      &:hover {
        color: $MainYellow;
      }
    }
  }
}
