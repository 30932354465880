@import "../../../../constants/constants.scss";

.formPagesITE {
  flex: 1;
  &__Title {
    display: flex;
    margin-bottom: 2rem;
    &__Resultats {
      justify-content: center;
    }
  }

  &__MainPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    &__text {
      font-family: "PulpDisplay", serif;
      font-size: 18px;
      font-weight: 500;
      color: $Stablegrey;
      margin-right: 1rem;
    }
  }

  &__Block {
    background-color: white;
    padding: 2rem;
    margin-bottom: 2rem;
  }

  &__BlockTitle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
  }

  &__BlockTitleLeftContainer {
    flex: 1;
    height: 100%;
    padding: 1rem 0;
    margin-right: 2rem;
  }

  &__BlockTitleLeft {
    display: flex;
    align-items: center;
  }

  &__SubTitle {
    margin-top: 2rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  &__Chevron {
    margin-right: 0.5rem;
    padding-top: 0.1rem;
    > img {
      height: 0.7rem;
    }
  }

  &__BlockContainer {
    overflow: hidden;
    transition: height $tabAnimation ease;
  }

  &__DuplicateContainer {
    display: flex;
    h2 {
      font-weight: 700;
      font-family: "DM Sans", serif;
      font-size: 18px;
      margin-right: 2rem;
    }
  }

  &__DeleteWall {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  &__Question {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__QuestionPhoto {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    &__QuestionTitle {
      font-family: "DM Sans", serif;
      font-weight: 400;
      font-size: 18px;
      color: $Stablegrey;
      margin-right: 1rem;
    }
  }

  &__QuestionLeftRight {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 41rem;
  }
  &__QuestionRight {
    margin-right: 4rem;
  }

  &__Button {
    display: flex;
    justify-content: flex-start;
    &__Wrapper {
      margin-top: 1rem;
    }
  }

  &__ButtonLeft {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  &__Category {
    margin-bottom: 20px;
  }
  &__CategoryTop {
    display: flex;
    align-items: center;
  }
  &__Trait {
    flex: 1;
    border-top: 1px solid $StableGrayLight04;
    margin-top: 2rem;
  }

  &__TraitDotted {
    flex: 1;
    border-top: 3px dashed $StableGrayLight04;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  &__WindowContainer {
    background-color: $AssuranceBlueLight06;
    padding: 1rem 2rem 2rem 2rem;
    margin-top: 2rem;
  }

  &__Trash {
    cursor: pointer;
    path {
      fill: $PowerRed;
    }
  }

  &__ToolTip {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  &__DuplicateContainer {
    display: flex;
    align-items: center;
  }

  &__BlueWrapper {
    background-color: $LightBlueBackground;
    border-radius: 40px;
    padding: 2rem 0;
    margin: 2rem;
    .title {
      text-align: left;
      font-size: 1.125rem;
      padding-left: 30px;
      font-weight: 700;
    }
    > div {
      padding: 0 25px;
    }
    .inputText {
      width: calc(100% - 4rem);
    }
  }
  &__BlueContainerCalcul {
    background-color: $LightBlueBackground;
    border-radius: 40px;
    padding: 2rem 0;
    margin: 2rem;
    font-size: 18px;
    text-align: left;
    padding-left: 30px;
  }
  &__Paragraph {
    font-size: 1rem;
    color: $Stablegrey;
    text-align: left;
    padding-left: 2rem;
  }
  &__Tranchee {
    margin: 50px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 1.313rem;
      color: $Stablegrey;
      font-weight: 400;
      position: relative;
      width: fit-content;
      margin: 0 auto;
      &::before {
        content: "";
        width: 140px;
        height: 1px;
        background-color: $StableGrayLight04;
        display: block;
        position: absolute;
        left: -150px;
        bottom: 4px;
      }
      &::after {
        content: "";
        width: 140px;
        height: 1px;
        background-color: $StableGrayLight04;
        display: block;
        position: absolute;
        right: -150px;
        bottom: 4px;
      }
    }
  }

  .ITE_questionContainer {
    margin-bottom: 34px;
    &__Resultats {
      &__Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 25px;
        gap: 15px;
      }
      &__Block {
        border: 1px solid $StablegreyLight;
        max-width: 85%;
        width: 100%;
        background-color: white;
        min-height: 64px;
        border-radius: 4px;
        padding: 19px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 54px;
        &__Element {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          p {
            font-family: "DM Sans", serif;
            font-weight: 500;
            font-size: 14px;
          }
          &__Input {
            max-width: 104px;
          }
          &__Tooltip {
            max-width: 618px;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
    &__LogistiqueContainer {
      display: flex;
      flex-direction: column;
      gap: 35px;
    }
  }
}

.Modale_Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.ITE {
  &_Pdf {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
