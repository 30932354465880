// Colors
$MainBlue: #3b4863;
$MainYellow: #ed8c00;
$MainOrange: #ed8c00;
$MainBlack: #0e1315;
$MainLightBlue: #0288d1;
$AssuranceBlue: #0073E0;
$LightBlueBackground: #dbe9f8;
$MainGreen: #71b051;
$MainRed: #d81159;
$MainPurple: #50249f;
$MainGrey: #5e5e5e;
$Grey80: #7e7e7e;
$Grey60: #9e9e9e;
$Grey35: #c7c7c7;
$Grey25: #d7d7d7;
$Grey10: #efefef;
$Grey05: #f7f7f7;
$GreyInputBorder: #8a9ea8;
$Yellow80: #ffba54;
$Yellow60: #ffcb7f;
$Yellow35: #ffe1b4;
$Yellow25: #ffe9c9;
$Yellow10: #fff6ea;
$Yellow05: #fffbf4;
$RedErrorMessage: rgb(248, 66, 66);
$BorderColor: #a0a1b0;
$Stablegrey: #50545f;
$StablegreyLight: #e9eaeb;
$StablegreyDark01: #44474F;
$MainBackgroundColor: #f8f8f8;
$AssuranceBlueLight04: #c3daf3;
$AssuranceBlueLight06: #f3f8fc;
$StableGrayLight: #e9eaeb;
$StableGrayLight02: #7c7f87;
$StableGrayLight03: #a8aaaf;
$StableGrayLight04: #e9eaeb;
$BackgroundColorTwo: #f5f6f7;
$OffBlack: #191b1e;
$LightYellowBackground: #fff5e0;
$PowerRed: #e02819;
$PowerRedDark01: #b0261b;

// Animations
$tabAnimation: 300ms;

// Responsive width
$mobile: 360px;
$smallWindow: 576px;
$mediumWindow: 768px;
$largeWindow: 1180px;
$windowsWidth: 1200px;

// header values
$topNavBarHeight: 5.75rem;
