@import "../../../../constants/constants.scss";

.potentialTranchees {
  display: flex;
  flex-direction: column;
  flex: 1;
  &__ProductName {
    margin-top: 1rem;
    margin-bottom: 1rem;
    p {
      font-size: 1.5rem;
    }
  }

  &__Tranchee {
    padding-top: 2.5rem;
    > p {
      font-size: 1.5rem;
      text-align: center;
      font-weight: 700;
    }
  }
}
