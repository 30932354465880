@import "../../../../../constants/constants.scss";

.lineDividedBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__Label {
    font-family: "DM Sans", serif;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: $StablegreyDark01;
  }
  &__Value {
    position: absolute;
    left: 52%;
    font-family: "DM Sans", serif;
    font-weight: 700;
    font-size: 14px;
  }
  &__DividingLine {
    border: 1px solid $StableGrayLight02;
    width: 1px;
    height: 23px;
    position: absolute;
    left: 44.5%;
  }
}
