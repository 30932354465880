@import "../../constants/constants.scss";

.progressBar {
  display: flex;
  align-items: center;

  &__PreviousButton {
    color: $MainGrey;
    font-weight: 700;
    height: 100%;
    min-width: 13.5%;
  }
}
