.inputNumber {
  margin-left: 2rem;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__Title {
    font-size: 1.1rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  &__InputComponent {
    max-width: 12rem;
  }
  &__PreTitleIndication {
    display: inline-block;
    padding: 2px;
    border-radius: 50%;
    margin-right: 5px;
    height: 1.3rem;
    width: 20px;
    text-align: center;
    color: white;
  }
}
