@import "../../../../constants/constants.scss";

.selectPans {
  &__Top {
    // margin-left: 2rem;
    display: flex;
    // margin-top: 2rem;
    align-items: center;
  }
  &__Title {
    margin-right: 1.5rem;
    p {
      font-size: 1.1rem;
    }
  }

  &__Middle {
    display: flex;
    justify-content: center;
    margin: 1.5rem;
  }

  &__Buttons {
    border: 1px solid $AssuranceBlue;
    margin-right: 1rem;
    padding: 1rem 2rem;
    border-radius: 2rem;
    cursor: pointer;
    p {
      font-weight: 700;
    }
  }
  &__Active {
    background-color: $AssuranceBlue;
    p {
      color: white;
    }
  }
  &__Bottom {
    background-color: $MainYellow;
    display: flex;
    justify-content: center;
    padding: 0.3rem;
    margin: 0 -20px;
    p {
      font-size: 1.5rem;
      font-weight: 700;
      color: white;
    }
  }
  &__Category {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $Stablegrey;
    text-align: left;
    display: flex;
    flex-direction: column;
    h4 {
      font-weight: 700;
      font-size: 1.7rem;
      letter-spacing: 0.15rem;
      padding-bottom: 0rem;
    }
  }

  &__CategoryWithImageGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  &__CategoryImage {
    width: 100%;
    display: flex;
    align-items: center;

    > img {
      width: 100%;
      height: auto;
    }
  }

  &__CategoryTop {
    display: flex;
    align-items: center;

    // this applies only if parent is display: grid
    grid-column: span 2;
  }
  &__Trait {
    flex: 1;
    border-top: 1px solid $StableGrayLight04;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  &__Question {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
