@import "../../../constants/constants.scss";

.welcome-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1.563rem;
  align-items: flex-start;
  margin-bottom: 4.625rem;
  color: #ffffff;
  @media (max-width: $smallWindow) {
    display: none;
  }
  .client-portal {
    color: $MainYellow;
    font-size: 0.75rem;
    margin-bottom: 0.313rem;
  }

  &__userName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.313rem;

    .message {
      font-size: 1.313rem;
    }

    .userNames {
      font-weight: 700;
    }
  }

  &__userPicture {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    > img {
      border-radius: 50%;
    }
  }
}
