@import "../../../../constants//constants.scss";

.uploadPicturesComponent {
  margin-top: 1rem;
  &__Container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__ImageList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  &__singleImageContainer {
    width: 131px;
    height: 157px;
    position: relative;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  &__validatedImage {
    padding: 0.1rem;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
    position: absolute;
    right: 0;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      padding: 0.025rem;
    }
  }

  &__FormInput {
    .addImageButton {
      .buttonContent {
        > img {
          -webkit-filter: drop-shadow(0px 2px 6px -1px rgba(16, 66, 69, 0.14));
          filter: drop-shadow(0px 2px 6px -1px rgba(16, 66, 69, 0.14));
        }

        > p {
          color: $StableGrayLight02;
          font-family: "PulpDisplay";
          font-size: 1.03rem;
        }
      }
    }
  }

  &__ErrorMessage {
    margin-top: 0.5rem;
    font-family: "PulpDisplay";
    font-size: 1.03rem;
  }
}
