@import '../../constants//constants.scss';

.logo {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  > img {
    height: 100%;
    width: 100%;
  }
}
