@import "../../../../constants/constants.scss";

.DropDownBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  &__QuestionTitle {
    font-size: 18px;
    color: $Stablegrey;
  }

  &__DropdownWrapper {
    min-width: 142px;
  }

  &__question-answer_container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.answerUnderTitle {
  flex-direction: column;
  align-items: flex-start;
}
