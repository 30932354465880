@import "../../../constants//constants.scss";

.header-menu {
  margin-right: 0.625rem;
  > p {
    font-size: 0.688rem;
    margin-bottom: 0.313rem;
  }
  svg {
    height: 1.313rem;
    filter: brightness(0) saturate(100%) invert(33%) sepia(7%) saturate(806%) hue-rotate(186deg) brightness(93%) contrast(91%)
  }
}
