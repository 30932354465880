@import "../../constants/constants.scss";

.login {
  background-color: white;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
