@import '../../constants/constants.scss';

.container {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  z-index: 110;
  background: rgba(0, 0, 0, 0.47);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__modalContainer {
    width: 100%;
    padding: 40px 20px 20px 20px;
    background-color: white;
    color: $MainBlack;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @media (min-width: $smallWindow) {
      padding-top: 20px;
      width: auto;
      min-width: 300px;
      max-width: 1150px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__Header {
    position: absolute;
    right: 20px;
  }

  &__closingCross {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    > img {
      width: 20px;
      height: 20px;
      color: white;
    }
  }
}
