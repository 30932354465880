@import '../../../../constants/constants.scss';

.selectInput {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    &__Title {
   margin-right: 1.5rem;
        p {
            font-size: 1.1rem;
        }
    }
}