@import "../../../../constants//constants.scss";

.ValidateCalepinage {
  &__Product {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  &__NumberOfPannels {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    p {
      margin-right: 1rem;
    }
  }

  &__ImageCalepinage {
    width: 100%;
    margin-bottom: 3rem;
    img {
      width: 100%;
      max-width: 800px;
      height: 500px;
      object-fit: cover;
      margin-left: -2rem;
    }
  }

  &__CalepinageValidated {
    p {
      text-align: left;
      margin-bottom: 1rem;
    }
  }
  &__PickCards {
    display: flex;
    margin-bottom: 2rem;
  }
  &__PickCard {
    display: flex;
    margin-right: 1rem;
  }
  &__Comment {
    margin-bottom: 2rem;
    p {
      text-align: left;
      margin-bottom: 1rem;
    }
  }

  &__NoCalepinage {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &__LoadingComponent {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  &__ErrorMessage {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
