@import "../../constants/constants.scss";

.subHeader {
  background-color: white;
  height: 4.375rem;

  &__ButtonBack {
    padding-right: 30px;
  }
  &__Container {
    background-color: white;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__Left {
    display: flex;
    align-items: center;
    color: $OffBlack;
  }
  &__Right {
    display: flex;
    align-items: center;
  }
  &__UserName {
    p {
      font-size: 24px;
      margin-left: 0.5rem;
      font-weight: 700;
      font-family: "PulpDisplay", serif;
    }
  }
  &__Commercial {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2rem;
    p {
      font-family: "DM Sans", serif;
      font-weight: 700;
      font-size: 16px;
      color: $Stablegrey;
      &:first-child {
        font-family: "DM Sans", serif;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0.3rem;
        color: $Stablegrey;
      }
    }
  }
  &__PhoneNumber {
    background-color: white;
    color: $MainGrey;
    padding: 0rem 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    &__True {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1.4rem;
      background-color: #ed8c00;
      border-radius: 25px;
      color: white;
      height: 32px;
      font-family: "DM Sans", serif;
      font-weight: 700;
      font-size: 16px;
    }
  }

  &__Teams {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    background-color: white;
    padding: 6px;
    margin-left: 1rem;
    img {
      height: 90%;
    }
  }
}
