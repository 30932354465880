@import "../../../../constants//constants.scss";

.formPages {
  flex: 1;
  &__Category {
    padding-top: 4rem;
    padding-bottom: 1rem;
    color: $Stablegrey;
    text-align: left;
    display: flex;
    h4 {
      font-weight: 700;
      font-size: 1.7rem;
      letter-spacing: 0.15rem;
      padding-bottom: 0rem;
    }
    &:first-of-type {
      padding: 1rem 0;
    }
  }
  &__CategoryTop {
    display: flex;
    align-items: center;
  }
  &__Trait {
    flex: 1;
    border-top: 1px solid $StableGrayLight04;
    margin-top: 1rem;
    margin-left: 2rem;
  }
  &__BlueWrapper {
    background-color: $LightBlueBackground;
    border-radius: 40px;
    padding: 2rem 0;
    margin: 2rem;
    .title {
      text-align: left;
      font-size: 1.125rem;
      padding-left: 30px;
      font-weight: 700;
    }
    > div {
      padding: 0 25px;
    }
    .inputText {
      width: calc(100% - 4rem);
    }
  }
  &__BlueContainerCalcul {
    background-color: $LightBlueBackground;
    border-radius: 40px;
    padding: 2rem 0;
    margin: 2rem;
    font-size: 18px;
    text-align: left;
    padding-left: 30px;
  }
  &__Paragraph {
    font-size: 1rem;
    color: $Stablegrey;
    text-align: left;
    padding-left: 2rem;
  }
  &__Tranchee {
    margin: 50px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 1.313rem;
      color: $Stablegrey;
      font-weight: 400;
      position: relative;
      width: fit-content;
      margin: 0 auto;
      &::before {
        content: "";
        width: 140px;
        height: 1px;
        background-color: $StableGrayLight04;
        display: block;
        position: absolute;
        left: -150px;
        bottom: 4px;
      }
      &::after {
        content: "";
        width: 140px;
        height: 1px;
        background-color: $StableGrayLight04;
        display: block;
        position: absolute;
        right: -150px;
        bottom: 4px;
      }
    }
  }
}
