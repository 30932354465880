@import "../../../../constants//constants.scss";

.pickMultipleCards {
  margin-left: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__Title {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    p {
      font-size: 1.1rem;
    }
  }
  &__pickCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
  }
  &__Card {
    display: flex;
    justify-content: center;
  }
  &__Card:last-child {
    margin-bottom: 0px;
  }
}
