.inputText {
  margin-left: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 4rem);
  &__Title {
    font-size: 1.1rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  &__InputComponent {
    flex: 1;
  }
}
