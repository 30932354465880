@import "../../../constants//constants.scss";

.reset-password-form {
  width: auto;
  box-sizing: border-box;
  background-color: white;
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    > div {
      margin-top: 40px;
    }
  }
  .reset-password-inputs {
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .reset-password-notice {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .reset-link-check {
        display: flex;
        align-items: center;
      }
      .reset-password-user-email {
        font-size: 14px;
        color: $MainBlue;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        > span {
          color: $MainYellow;
        }
      }
      .reset-password-instruction {
        font-size: 12px;
        color: $Grey60;
        font-style: italic;
      }
    }
    .input-container {
      margin-bottom: 20px;
      height: 3rem;
    }
  }
  .reset-password-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 10px;
  }
  .reset-password-buttons {
    flex: 1;
  }
}
