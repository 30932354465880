.uploadPictures {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  &__Title {
    font-size: 1.1rem;
    margin-right: 1.5rem;
  }

  &__uploadPictureComponent.flex-grow {
    flex-grow: 1;
  }
}
