@import "../../../constants//constants.scss";

.reset-password-welcome {
  flex: 1;
margin-bottom: 2rem;
  box-sizing: border-box;
  flex: none;
  .reset-password-welcome-message {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    > p {
      font-size: 22px;
      font-weight: 700;
      margin-right: 8px;
      color: $MainYellow;
      &:nth-child(2) {
        color: $MainBlue;
      }
    }
  }
}
