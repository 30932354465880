@import "../../../constants/constants.scss";

.FinalCommentComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  padding: 2rem;
  &__TextBlock {
    text-align: center;
    font-family: PulpDisplay;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  &__TextArea {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    textarea {
      background-color: $MainBackgroundColor;
      border: 0.5px solid $StableGrayLight04;
      border-radius: 0.5rem;
      width: 28.25rem;
      height: 7.7rem;
      padding: 0.5rem;
      font-family: PulpDisplay;
      font-size: 0.9rem;
    }
  }
  &__Checkbox {
    margin-top: 1rem;
    position: relative;
    font-family: "DM Sans";
    display: flex;
    align-items: center;
    p {
      margin-left: 1rem;
    }
  }
  &__Buttons {
    margin-top: 2rem;
  }
}
