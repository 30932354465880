@import "../../../constants/constants.scss";

.typeOfProductIcon {
  width: fit-content;
  font-family: "PulpDisplay";
  font-size: 1rem;

  &__IconStyle {
    padding: 3px 8px;
    border-radius: 25px;
  }
  .PV {
    background-color: #7fb069;
  }

  .CHA {
    background-color: #2aafe2f7;
  }

  .CET {
    background-color: #fcca46f7;
  }

  .ISO {
    background-color: #7287b6;
  }

  .PAC {
    background-color: #b95f89;
  }

  .STO {
    background-color: #e9db18f7;
  }
}
