@import "../../constants/constants.scss";

.pickPositionMapModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  padding: 5.0625rem 4.375rem;
  min-height: 18rem;

  &__TextBlock {
    text-align: center;
    font-family: DM Sans;
    h3 {
      font-size: 1.3125rem;
      margin-bottom: 3rem;
      font-weight: 700;
    }
    h4 {
      font-size: 1.3125rem;
      font-weight: 400;
      margin-bottom: 2rem;
    }
  }

  &__ButtonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
