.ConfirmationSendRapportComponent {
  &__TextBlock {
    text-align: center;
    font-family: PulpDisplay;

    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  &__Buttons {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__IsLoading {
    display: flex;
    justify-content: center;
  }
  &__SuccessMessage {
    min-height: 1rem;
    text-align: center;
    > p {
      font-family: PulpDisplay;
    }
  }
}
