@import "../../constants/constants.scss";

.TooltipInfoForm {
  background-color: $LightYellowBackground;
  border-radius: 4px;
  padding: 18px 28px 18px 24px;

  display: flex;
  align-items: center;
  gap: 12px;

  &__ImageContainer {
    width: 24px;
    height: 24px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  &__TextContainer {
    text-align: left;
    color: $OffBlack;
    font-size: 1rem;
    line-height: 1.3019rem;
  }
}
