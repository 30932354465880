@import "../../../constants/constants.scss";

.visitDetailsProductCard {
  &__Container {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    margin-top: 1.5rem;
  }

  .rightLeftBlock {
    box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 22px 16px;
  }

  &__LeftBlock {
    flex: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    text-align: left;

    background-color: #ffffff;
  }

  &__RightBlock {
    flex: 0.6;
    background-color: $AssuranceBlueLight04;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__buttons {
      flex: 1.2;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      gap: 1.2rem;

      margin-bottom: 3rem;
    }

    .select-visit-status {
      flex: 0.8;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: left;
    }
  }

  .techVisitDetails {
    > p {
      color: $Stablegrey;

      > span {
        color: black;
        margin-left: 1rem;
      }
    }
  }

  .comment {
    > p {
      font-size: 0.9rem;
      color: $Stablegrey;

      > span {
        font-size: 1rem;
        color: black;
        display: block;
      }
    }
  }

  .select-visit-status {
    > label {
      margin-bottom: 0.8rem;
      color: $Stablegrey;
      font-family: "PulpDisplay";
    }
  }
  .select-visit-status-value{
    text-align: center;
    padding: 5px 30px;
    background-color: $AssuranceBlueLight06;
  }
}
