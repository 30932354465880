@import "../../constants/constants.scss";

.CalendarDayLine {
  width: 100%;
  text-align: left;
  position: relative;
  margin-top: 13px;

  &::after {
    content: "";
    position: absolute;
    bottom: 2px;
    width: 90%;
    left: 10%;
    overflow: hidden;
    height: 1px;
    background-color: $StableGrayLight02;
  }
  p {
    color: $MainYellow;
    font-weight: 0.813rem;
    width: min-content;
  }
}

.CalendarDayLineNextWeek {
    width: 100%;
    text-align: left;
    position: relative;
    margin-top: 13px;
  
    &::after {
      content: "";
      position: absolute;
      bottom: 2px;
      width: 80%;
      left: 20%;
      overflow: hidden;
      height: 1px;
      background-color: $StableGrayLight02;
    }
    p {
      color: $MainYellow;
      font-weight: 0.813rem;
      width: max-content;
    }
}
