@import "../../../constants/constants.scss";

.visitDetailsClientInfo {
  background-color: $StablegreyLight;
  &__Container {
    display: flex;
    justify-content: space-between;

    padding: 1rem 0;
  }

  .column {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    border-left: 1px solid black;
    padding-left: 27px;
  }

  .column:first-child {
    border: none;
    padding-left: 0;
  }

  .column:last-child {
    justify-content: center;
  }

  &__Label {
    font-size: 0.813rem;
  }
}
