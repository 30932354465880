@import "../../constants/constants.scss";

.header {
  background-color: #ffffff;
  height: $topNavBarHeight;
  display: flex;
  justify-content: space-between;
  color: $Stablegrey;
  align-items: center;
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 4px rgba(18, 40, 61, 0.04);
  z-index: 100000;

  @media (max-width: $smallWindow) {
    padding: 0 1.25rem;
    box-shadow: 0px 4px 4px rgba(18, 40, 61, 0.04);
  }

  &__Container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .header-right {
    display: flex;
    > button {
      @media (max-width: $smallWindow) {
        display: none;
      }
    }
  }
  .header-responsive {
    flex: 1;
    display: none;
    > a {
      margin-left: -2.5rem;
    }
    @media (max-width: $smallWindow) {
      display: flex;
      justify-content: center;
    }
  }

  .header-logo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media (max-width: $smallWindow) {
      justify-content: center;
    }
  }

  .header-left {
    display: flex;
    align-items: center;
    > a > img {
      margin-top: 0.625rem;
      margin-left: 0.188rem;
      @media (max-width: $smallWindow) {
        display: none;
      }
    }
  }
}
