@import "../../../../constants/constants.scss";

.potentialPans {
    display: flex;
    flex-direction: column;
flex : 1;
&__ProductName {
    margin-top: 1rem;
    margin-bottom: 1rem;
    p {
font-size: 1.5rem;
    }
}
}