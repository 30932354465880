@import "../../../constants/constants.scss";

.singleVisit {
  margin-top: 22px;
  width: 100%;
  position: relative;
  &__Container {
    background-color: #ffffff;
    box-shadow: 0px 2px 27px -16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 4.688rem;
  }

  &__Content {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    font-size: 1rem;
  }

  &__TypeOfProductContainer {
    position: absolute;
    bottom: -10px;
    right: 70px;    
  }
}
