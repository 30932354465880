@import "../../../../constants/constants.scss";

.CheckboxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  &__Title {
    font-family: "DM Sans", serif;
    font-size: 16px;
    font-weight: 400;
    color: $Stablegrey;
    strong {
      font-family: "DM Sans", serif;
      font-size: 16px;
      font-weight: 700;
      color: $Stablegrey;
    }
  }
  &__question-answer_container {
    display: flex;
    gap: 20px;

    > p {
      text-align: left;
    }
  }
}

.answerUnderTitle {
  flex-direction: column;
  align-items: flex-start;
}
