@import "../../../constants/constants.scss";

.formWrapper {
  &__Header {
    background-color: $AssuranceBlue;
  }
  &__HeaderContainer {
    background-color: $AssuranceBlue;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
  }
  &__HeaderLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $Stablegrey;
    font-size: 0.9rem;
    p {
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  &__HeaderRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $Stablegrey;
    font-size: 1rem;
    > p {
      margin-right: 0.5rem;
    }
  }
  &__FormContent {
    background-color: white;
  }
  &__FormQuestionsFormatter {
    margin-top: 0.2rem;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    background-color: $MainBackgroundColor;
  }

  &__FormBottom {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  &__ProgressBar {
    background-color: white;
  }
}
