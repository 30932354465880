.inputText {
    margin-left: 2rem;
    margin-top: 2rem;
    width: 100%;

    &__Title {
        font-size: 1.1rem;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
    }
    &__UploadPicturesComponent {
        margin-top: 1rem;
    }
}