@import "../../constants/constants.scss";

.PickPositionMap {
  &__Map {
    position: relative;
    height: calc(100vh - #{$topNavBarHeight} - 11rem - 6.875rem);
    min-height: 25rem;
    width: 100vw;
    z-index: 0;
  }
  &__Buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6rem;
    padding-top: 2rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }
  &__Instruction {
    padding: 30px 0px;
    background-color: white;
    color: $Stablegrey;
    > h2 {
      padding-bottom: 0.625rem;
      font-size: 1.5rem;
    }
  }
}
