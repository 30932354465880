@import "../../../constants//constants.scss";

.reset-password-window {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  .simple-vertical-trait-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
