@import "../../../constants/constants.scss";

.authentication-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 1rem;
  padding: 0 1.5rem;
  &__EspaceClient {
    width: 95%;
    font-size: 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 30px 25px 15px;
    margin-top: 17px;
    margin-bottom: 17px;

    @media (min-width: $mediumWindow) {
      width: 100%;
      padding: 32px 100px 20px;
    }

    > p {
      font-size: 0.938rem;
      text-align: center;
    }

    > a {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      padding: 7px 18px;
      color: $MainGrey;
      font-weight: bold;
    }
  }
}
