@import "../../constants/constants.scss";

.LocalStorageViewer {
  background-color: white;
  padding: 2rem 2rem;
  > h1 {
    margin-bottom: 1rem;
  }

  &__Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__VTDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > li {
      display: flex;
      margin-bottom: 0.5rem;
    }
    p {
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }

  &__VTHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    margin-bottom: 1rem;
    margin-top: 1rem;
    > h2 {
      margin-bottom: 1rem;
    }
  }

  &__VTHeaderButtons {
    display: flex;
    gap: 1rem;
  }

  &__ModalButtons {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  &__VTData {
    text-align: left;
    max-width: 100%;
    line-height: 1.2rem;
    max-width: 100%;
    width: 100%;
    textarea {
      width: 100%;
      height: fit-content;
    }
  }
}
