@import "../../constants/constants.scss";

.Ranaglyphe {
  background-color: white;

  &__HeaderContainer {
    background-color: $StableGrayLight;
  }

  &__ViewerContainer {
    height: 56vh;
    position: relative;

    @media (max-width: $mediumWindow) {
      height: 68vh;
    }
  }

  &__ModelViewer {
    background-color: $StableGrayLight;
    -webkit-box-shadow: 0 0 10px #fff;
    box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
}
