@import "../../../constants/constants.scss";

.authentication-credentials {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: $mediumWindow) {
    flex: none;
    padding: 0;
    max-width: 320px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;

    @media (min-width: $mediumWindow) {
      margin-top: 20px;
    }
  }

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    > div {
      margin-top: 2.5rem;
    }
  }

  .login-notice {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mediumWindow) {
      margin-bottom: 0.938rem;
    }

    .register-successfull {
      font-size: 1rem;
      color: $MainGreen;
    }
  }

  .authentication-inputs {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  &__InputContainer {
    margin-bottom: 2rem;
    height: 25px;
    @media (min-width: $mediumWindow) {
      height: 35px;
    }
  }

  .authentication-message {
    margin-bottom: 1.2rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
  }

  .authentication-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    .go-to-forget-password {
      margin-top: 1rem;
      font-size: 1rem;
      width: 11rem;
      color: $MainBlue;
      text-decoration: underline;

      &:hover {
        color: $MainYellow;
      }
    }
  }
}
