.BonDeCommandeAModifierComponent {
  &__TextBlock {
    text-align: center;
    font-family: PulpDisplay;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  &__Buttons {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
