@import "../../../constants/constants.scss";

.visitNotDoableModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  padding: 2rem;
  min-height: 18rem;
  &__TextBlock {
    text-align: center;
    font-family: PulpDisplay;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  &__Select {
    margin-top: 1rem;
    width: 17rem;
  }

  &__Buttons {
    margin-top: 4rem;
  }
}
