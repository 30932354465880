@import "../../../constants/constants.scss";

.header-menu-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 1.75rem;
  height: 80%;
  flex: 1;
  > ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    > li {
      margin-bottom: 0.938rem;
      > a {
        font-size: 1.688rem;
        color: #ffffff;
        font-weight: 400;
        &:hover {
          font-size: 1.75rem;
        }
      }
      .selected {
        color: $MainYellow;
      }
    }
  }
}
