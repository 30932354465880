@import "../../../constants/constants.scss";

.header-menu-background {
  top: 0rem;
  left: 0rem;
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.header-menu-dropdown {
  top: $topNavBarHeight;
  left: 0rem;
  position: absolute;
  background-color: $AssuranceBlue;
  padding: 0.938rem 3.125rem 1.875rem;
  width: 60vw;
  height: calc(100vh - #{$topNavBarHeight});
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  .header-menu-top {
    display: flex;
    align-items: center;
    > .button-exit-cross {
      margin-top: 0.188rem;
    }
    > a {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-left: 0.938rem;
    }
  }
  > button {
    margin-top: 1.25rem;
  }
}

.animation-in {
  top: 0rem;
  left: 0rem;
  position: absolute;
  animation: appearFromLeft 0.5s ease-in-out forwards;
}

.animation-out {
  top: 0rem;
  left: 0rem;
  position: absolute;
  // animation: disappearFromLeft 0.5s ease-in-out forwards;
  // no animation for now until we find a solution to avoir animation to trigger on first load
  display: none;

  > .header-menu-background {
    display: none;
  }
}

@keyframes appearFromLeft {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes disappearFromLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    // this translate makes the menu go out of screen, to confirm if there's a better way
    // display: none cannot be added into animation
    transform: translateX(-1000px);
    opacity: 0;
  }
}

.header-menu-navigation-container {
  flex: 1;
}
