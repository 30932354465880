@import "../../constants/constants.scss";

.InfoBoxForm {
  position: relative;
  background-color: $LightBlueBackground;
  padding: 0.6875rem 1.25rem 0.5625rem 2.3125rem;
  margin: 1.5625rem 3.125rem 0;
  border-radius: 0.25rem;
  min-height: 2.5625rem; //image height
  &__ImageContainer {
    position: absolute;
    // image width : 41px , height: 41px
    top: 0;
    left: -1.2813rem; //image height/2
  }

  &__TextContainer {
    color: $OffBlack;
    font-size: 1rem;
    line-height: 1.3125rem;
  }
}
