@import "../constants//constants.scss";

.tab-content-enter {
  transform: translateY(-100%);
}
.tab-content-enter-active {
  transform: translateY(0%);
  transition: all $tabAnimation ease;
}
.tab-content-exit-active {
  transform: translateY(-100%);
  transition: all $tabAnimation ease;
}
.tab-chevron-enter {
  transform: rotate(0deg);
}
.tab-chevron-enter-active {
  transform: rotate(180deg);
  transition: all $tabAnimation ease;
}
.tab-chevron-enter-done {
  transform: rotate(180deg);
}
.tab-chevron-exit {
  transform: rotate(0deg);
  transition: all $tabAnimation ease;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

// COMMENTED TO TEST SLOW LOADING ISSUES ON MOBILE

// .slide-in-bottom {
//   opacity: 0;
//   transition: opacity 500ms ease-in;
// }

// .slide-in-bottom.appear {
//   -webkit-animation-name: slide-in-bottom;
//   -webkit-animation-duration: 1.2s;
//   -webkit-animation-timing-function: ease-out;

//   animation-name: slide-in-bottom;
//   animation-duration: 1.2s;
//   animation-timing-function: ease-out;

//   opacity: 1;
// }

// $total: 10;

// // total = number of maximum children
// @for $i from 1 through $total {
//   .slide-in-bottom.appear:nth-child(#{$i}){
//     animation-duration: $i *0.5s + 0.2s;
//   }
// }
