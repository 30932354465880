@import "../../../constants/constants.scss";

.potentialPan {
  padding-top: 1rem;
  h3 {
    margin-bottom: 1rem;
  }
  &__Informations {
    margin-left: 2rem;
    background-color: $AssuranceBlueLight04;
    margin-bottom: 1rem;
    padding: 1rem;
    width: 26rem;
    p {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
  &__Location {
    margin-left: 2rem;
    display: flex;
    margin-right: 2rem;
    flex: 1;
    align-items: center;
    p {
      font-size: 1.1rem;
      margin-right: 1rem;
    }
  }
  &__CalculateButton{
    background-color: $AssuranceBlue;
    border: 1px solid $AssuranceBlue;
    border-radius: 15px;
    width: fit-content;
    cursor: pointer;
    color: white;
    font-family: "PulpDisplay";
    padding: 1rem 2rem;
    margin-left: 2rem;
  }
}
