@import "../../constants/constants.scss";

.SubHeaderProductInformation {
  &__Header {
    background-color: $StableGrayLight;
  }
  &__HeaderContainer {
    background-color: $AssuranceBlue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.1rem 0rem;
  }
  &__HeaderLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__ProductName {
      color: white;
      font-size: 14px;
      font-family: "DM Sans", serif;
      font-weight: 500;
    }
    p {
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  &__HeaderRight {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    color: white;
    font-size: 1rem;
    > p {
      margin-right: 0.5rem;
    }
  }

  &__ARButton {
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-start;
    gap: 7px;

    padding: 4px 8px;

    cursor: pointer;

    > img {
      max-width: 20px;
      width: 100%;
      height: auto;
    }
  }
}
