@import "../../../constants//constants.scss";

.arrayHeader {
  &__Container {
    display: flex;
    justify-content: space-around;

    > p {
      font-size: 0.813rem;
      line-height: 1rem;
      color: $Stablegrey;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    i::before {
      font-size: 1rem;
      margin-left: 0.2rem;
    }
  }
}

.lastItemOfArray {
  width: 34px;
}

.flex-item {
  flex: 1;
  text-align: left;
  text-align: center;
}

.flex-item.size-08 {
  flex: 0.8;
}
.flex-item.size-12 {
  flex: 1.2;
}
