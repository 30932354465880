@import "../../constants/constants.scss";

.mesVisites {
  min-height: calc(100vh - #{$topNavBarHeight});
  &__Header {
    color: $Stablegrey;
    font-family: "PulpDisplay";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;

    margin-top: 3rem;
    margin-bottom: 3rem;

    display: flex;
    justify-content: space-between;
  }

  &__NavigationButtonsContainer {
    display: flex;
    gap: 2rem;
  }

  &__ShowVisitsButton {
    background-color: $GreyInputBorder;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 15px;
    border-radius: 30px;
    transition: box-shadow 0.3s;
    cursor: pointer;

    color: white;
    font-size: 14px;

    img {
      transform: rotate(180deg);
      height: 25px;
      margin-left: 10px;
      transition: transform 0.5s ease;
    }

    &__Active {
      background-color: white;
      border: solid 2px $AssuranceBlue;
      color: $AssuranceBlue;

      img {
        filter: brightness(0) saturate(100%) invert(31%) sepia(61%)
          saturate(1913%) hue-rotate(188deg) brightness(99%) contrast(112%);
        transform: rotate(270deg);
      }
    }
  }

  &__Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__SingleVisitContainer {
    width: 100%;
  }
  &__NoVisit {
    margin-top: 2rem;
  }
}
